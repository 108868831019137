import { defineStore } from "pinia";

export const useloggedUserInfo = defineStore({
    id: "UserInfo",
    state: () => ({
        isAuth: false,
        user:null,
        classicEditor: null
       }),
    getters: {
        GetUserInfo: (state) => {
            if (state.user) {
                let profile = state.user.profile;
                return {
                    ID: profile.id,
                    Token: state.user.access_token,
                    CompanyId: profile.companyId,
                    Name: profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
                    UserType: profile['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
                    TimeFormat: profile.timeFormat,
                    Email: profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
                    Avatar: profile.avatar,
                    ContainerName: profile.containerName,
                    CompanyLogo: profile.companylogo,
                    Currency: profile.Currency,
                    Gender: profile.gender,
                    PageSize: profile.PageSize,
                    IsWithCC: profile.IsWithCC,
                    DoNotShowDirtyCheck: profile.DoNotShowDirtyCheck,
                    IsClientCA: profile.IsClientCA,
                    CompanyStatusCode: profile.companyStatusCode,
                    LoginStatus: profile.loginStatus,
                    TimeZoneStatus: profile.TimeZoneStatus,
                    IsInTrial: profile.IsInTrial,
                    IsDefault: profile.isdefault,
                    TimeZone: profile.timezone,
                    MenuType: profile.menutype,
                    MenuTypeCode: profile.menutype,
                    Theme: profile.theme,
                    OrgComapanyId: profile.OrgComapanyId,
                    CompanyName: profile.companyName,
                    EnableMyDashboard: profile.EnableMyDashboard,
                    FlagNotificationCheck: profile.FlagNotificationCheck,
                    IsVendor: profile.IsVendor,
                    IsInitialSetup: profile.initialSetup,
                    DateFormat: profile.dateFormat,
                    IsContextMenuClosed: true
                }
            }
            return null;
        },
        getIsAuth: (state) => {
            return state.user ?true: false;
        },
        CLASSIC_EDITOR: (state) => {
            return state.classicEditor;    
        }
    },
    actions: {
        async setUser(userProfile) {
            this.user = userProfile;          
        },
        async setClassicEditor(val) {
            this.classicEditor = val;          
        },
        async SetUserInfo(key, value) {
            if (key == "menutype") {
                this.MenuType = value;
            }
            else if (key == "contextmenuclosed") {
                this.IsContextMenuClosed = value;
            }
            else if (key == "name") {
                //debugger;
                this.Name = 'Radhay Chauhan';
            }
        }
    },
    persist: true,
});

export const moduleStore = defineStore({
    id: "moduleMaster",
    state: () => ({
        ModuleId: 0,
    }),
    getters: {
        GetModuleId: (state) => state.ModuleId,
    },
    actions: {
        SetModuleId(value) {
            this.ModuleId = value;
        },
    },
});