import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL
const baseDomain = process.env.VUE_APP_API_URL; 
const baseURL = `${baseDomain}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;

const baseDomainAsset = process.env.VUE_APP_ASSET_API; 
const baseURLAsset = `${baseDomainAsset}`;

const baseDomainEvent = process.env.VUE_APP_EVENT_APP_URL;
const baseURLEvent = `${baseDomainEvent}`;

const instance = axios.create({
  baseURL,
});
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
  });
const instanceCommonFile = axios.create({
    baseURL:baseURLCommon,
  });

  const instanceAsset = axios.create({
    baseURL:baseURLAsset,
  });

  const instanceEvent = axios.create({
    baseURL:baseURLEvent,
  })
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = config.url=='/Configuration'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instanceEvent.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instanceCommonFile.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"]= "multipart/form-data";   
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    }, 
    (error) => {
        return Promise.reject(error);
    }
);

instanceAsset.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8";
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
export default {instance,instanceCommon,instanceCommonFile,instanceAsset,instanceEvent};
