<template>
    <div>
        <div v-if="isVisible" class="overlay" id="divProgress" style="display: block;">
            <div class="lds-ring">
                <img  style="max-width: 50%; position: absolute; top: 18px; left: 19px; " class="blob" alt="">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            isVisible: { type: Boolean, required: true, },
            text: { type: String, required: false, default: "" },
        },
    }
</script>

<style>
   
    /**********************loader***********************/
    .overlay {
        background: rgba(0,0,0,0.8);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lds-ring img {
        display: none !important;
    }

    /******New Search Loader - Harjit Singh 17May2022**********/
    @keyframes load {
        0% {
            transform: translateX(40px);
        }

        50% {
            transform: translateX(-30px);
        }

        100% {
            transform: translateX(40px);
        }
    }

    .lds-ring {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 10px;
        background: #3498DB;
        border-radius: 5px;
        -webkit-animation: load 1.8s ease-in-out infinite;
        animation: load 1.8s ease-in-out infinite;
    }

    .lds-ring::before, .lds-ring::after {
        position: absolute;
        display: block;
        content: "";
        -webkit-animation: load 1.8s ease-in-out infinite;
        animation: load 1.8s ease-in-out infinite;
        height: 10px;
        border-radius: 5px;
    }

    .lds-ring:before {
        top: -20px;
        left: 10px;
        width: 40px;
        background: #EF4836;
    }

    .lds-ring:after {
        bottom: -20px;
        width: 35px;
        background: #F5AB35;
    }
    /******New Search Loader - Harjit Singh 17May2022**********/
</style>