<template>
    <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <em class="fa fa-home"></em><RouterLink to="/User/Home">Home</RouterLink>
          </li>
          <template v-for="item in breadcrumbList">
            <li class="breadcrumb-item" v-if="item.link"><RouterLink :to="item.link" >{{item.name}}</RouterLink></li>
            <li class="breadcrumb-item active" v-if="!item.link">{{item.name}}</li>
          </template>
        </ol>
</template>
<script>
    export default{
       name: "breadcrumb",
       data: function(){
            return{
                breadcrumbList: []
            }
       },
       mounted() {
            this.updateBreadcrumb();
       },
       watch: {
           '$route':function(){
                this.updateBreadcrumb();
           }
       },
       methods: {
            updateBreadcrumb: function(){
                
                if(Object.keys(this.$route.meta).length > 0 && Object.keys(this.$route.meta).indexOf("breadcrumb") > -1){
                    if(this.$route.meta.breadcrumb[1].link == '/Dashboard/Home')
                    {
                        this.breadcrumbList = [];
                    }
                    else
                    {
                        this.breadcrumbList = this.$route.meta.breadcrumb;

                    }
                    
                }else{
                    this.breadcrumbList = [];
                }
            }
       }
    }
</script>