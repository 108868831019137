import { param } from "jquery";
import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  async CustomReportListing(params) {
    return http.instanceCommon.get("/ManageDynamicReport/GetMainReportListing", { params });
  }
  async GetDateRangeFilterDDL(){
    return await http.instanceCommon.get("/ManageDynamicReport/GetDateRangeList");
  }
  async GetMainReportSearching(params) {
    return http.instanceCommon.get("/ManageDynamicReport/GetMainReportSearching?SearchedField=" + params);
  }
  GetFilterDDLselectvalue(params) {
    return http.instanceCommon.get("/ManageDynamicReport/GET_DROPDOWNVALUE_FOR_FIELD?Field_Id=" + params);
  }
  async GetDataOperator(params) {
    return http.instanceCommon.get("/Common/GetOperator?" + params);
  }
  CommonReportDelete(params) {
    return http.instanceCommon.post("/Common/CommonDeleteData?" + params);
  }
  async AddReporting(params) {
    return await http.instanceCommon.get("/common/GetModulesSubModules?" + params);
  }
  async ShareUserRole(params) {
    return await http.instanceCommon.post("/Share/ShareUserRole?" + params);
  }
  async ManagefolderListing(params) {
    return await http.instanceCommon.get("/ReportFolder/GetFolderListing?" + params);
  }
  async DDLfolderListing(params) {
    return await http.instanceCommon.get("/ReportFolder/GetFolderData?" + params);
  }
  async ShareUserRoleListing(params) {
    return await http.instanceCommon.get("/Share/GetShareUserRoleListing?" + params);
  }
  DeleteShareUserRole(params) {
    return http.instanceCommon.post("/Share/DeleteAssignedShareUserRole?" + params);
  }
  UpdateShareUserRole(params) {
    return http.instanceCommon.post("/Share/ShareUserRole?" + params)
  }
  AddNewCustomFolder(params) {
    return http.instanceCommon.post("/ReportFolder/AddNewFolder?" + params)
  }
  GetAddReportDashboard(params) {
    return http.instanceCommon.get("/Common/GetModulesSubModules?" + params);
  }
  EditReportFolder(params) {
    return http.instanceCommon.get("/ReportFolder/EditReportFolder?" + params);
  }
  PreviewReport(params) {
    return http.instanceCommon.post("/ManageDynamicReport/AddDynamicReportFilters", params);
  }
  VerifyExpressionFromDB(params) {
    return http.instanceCommon.get("/ManageDynamicReport/Verify_Calculated_Expression?" + params);
  }
  GetFunctionList() {
    return http.instanceCommon.get("/ManageDynamicReport/GetAggrigateFunctionDetail");
  }
  AddDynamicreport(params) {
    return http.instanceCommon.post("/ManageDynamicReport/AddDynamicReport?" + params);
  }
  GetDateDetails() {
    return http.instanceCommon.get("/ManageDynamicReport/GetDateFormatsDetail");
  }
  GetChartTypes() {
    return http.instanceCommon.get("/ManageDynamicReport/GetChartMasterDetail")
  }
  DeleteReportFolder(params) {
    return http.instanceCommon.post("/ReportFolder/DeleteReportFolder?" + params);
  }
  SaveAllDynamicReport(params) {
    return http.instanceCommon.post("/ManageDynamicReport/SaveDynamicReport", params);
  }
  GetReportDetailsbyId(params) {
    return http.instanceCommon.get("/ManageDynamicReport/GetDataOfDynamicReportRunAndView?" + params);
  }
  UserDDL() {
    return http.instanceCommon.get("/Users");
  }
  GetRolesDDl(params) {
    return http.instanceCommon.get("/roles/get_roles_list?" + params);
  }
  GetListFields(params) {
    return http.instanceCommon.get("/FormLayout/DynamicReportViewListFields?" + params);
  }
  Editdyanmicreport(params) {
    return http.instanceCommon.get("/ManageDynamicReport/EditDynamicReportById?" + params);
  }
  ScheduleEmail(params) {
    return http.instanceCommon.post("/ManageDynamicReport/Email_Scheduler?" + params);
  }
  SendEmailService(params) {
    return http.instanceCommon.post("/ManageDynamicReport/SendReportEmail", params);
  }
  FetchTimeSlotService() {
    return http.instanceCommon.get("/ManageDynamicReport/GET_DROPDOWNVALUE_FOR_TIMESLOT");
  }
  GetExcelReport(params, JsonData = "null") {
    return http.instanceCommon.post("/ManageDynamicReport/GetExcelReportbytes?" + params, JsonData, { responseType: 'blob' });
  }
  DownloadExceedRecords(params){
    return http.instanceCommon.post("/ManageDynamicReport/Get_Download_Excel_Link", params);
  }
  InsertDynamicReportsLog(params){
    return http.instanceCommon.post("/ManageDynamicReport/InsertDynamicReportsLog",params);
  }
  async leadListing(params) {
    return await http.instance.get("/LeadListing/", { params });
  }
  GetLeadListing(params) {
    return http.instance.get("/LeadListing?" + params);
  }
  async leadStatus(params) {
    return await http.instanceCommon.get("/Form/GetDDLData/", { params });
  }
  statusdropdown(params) {
    return http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
  }
  SearchCriteriaListing(params) {
    return http.instance.get("/Form/SearchCriteriaListing/", { params });
  }
  SearchCriteriaById(params) {
    return http.instance.get("/Form/SearchCriteriaById?" + params);
  }
  GetOperator(params) {
    return http.instance.get("/Form/GetOperator?" + params);
  }

  GetFieldName(params) {
    return http.instance.get("/Form/GetFieldName?" + params);
  }
  async GetDDLData(params) {
    return await http.instanceCommon.get("/Common/GetDDLData?"+  params );
  }

  SaveFilter(params) {
    return http.instance.post("/Form/SaveFilter", { postString: params })
  }

  GetFavDashboard() {
    return http.instanceCommon.get("/Common/GetFavDashboard");
  }
  GetCustomizeShortcutMenu() {
    return http.instanceCommon.get("/Dashboard/FullMenu");
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  create(data) {
    return http.instance.post("/user", data);
  }
  update(id, data) {
    return http.instance.put("/user/${id}", data);
  }
  delete(id) {
    return http.instance.delete("/user/${id}");
  }
  deleteAll() {
    return http.instance.delete("/user");
  }
  findByNam(name) {
    return http.instance.get("/user?name=${name}");
  }
  async getLeaOwnerBySearch(params) {
    return await http.instance.get("/Form/GetDDLData?" + params);
  }
  getFormLayout(params) {
    return http.instance.get("/FormLayout?" + params)
  }
  getQuickViewDetails(params) {
    return http.instance.get("/FormLayout/View?" + params)
  }
  getFollowupList(params) {
    return http.instance.get("/ActivityListing?" + params)
  }
  // deleteActivityData(params) {
  //   return http.instance.post("/Form/CommonDelete", { postString: params })
  // }
  getDDLData(params) {
    return http.instance.get("/Form/GetDDLData?" + params);
  }
  updateActivityData(params) {
    return http.instance.get("/updateActivity?" + params)
  }
  SaveLead(params) {
    return http.instance.post("/SaveLead", { postString: params })
  }
  DealListing(params) {
    return http.instance.get("/DealListing/", { params });
  }
  GetDealListing(params) {
    return http.instance.get("/DealListing?" + params);
  }
  DealStatus(params) {
    return http.instance.get("/Form/GetDDLData/", { params });
  }
  SaveNoteData(params) {
    return http.instance.post("/SaveNote", { postString: params })
  }
  CommonDelete(params) {
    return http.instance.post("/Form/CommonDelete", { postString: params })
  }
  Addtask(params) {
    return http.instance.post("/Addtask", { postString: params })
  }
  FormLayoutView(params) {
    return http.instance.get("/FormLayout/View?" + params)
  }
  TimelineListing(params) {
    return http.instance.get("/Form/TimelineListing?" + params)
  }
  ActivityListing(params) {
    return http.instance.get("/ActivityListing?" + params)
  }
  AttachmentListing(params) {
    return http.instance.get("/AttachmentListing?" + params)
  }
  NoteListing(params) {
    return http.instance.get("/NoteListing?" + params)
  }
  EmailListing(params) {
    return http.instance.get("/Form/EmailListing?" + params)
  }
  ContactListing(params) {
    return http.instance.get("/ContactListing?" + params)
  }
  SaveProductPrice(params) {
    return http.instance.post("/SaveProductPrice", { postString: params })
  }
  getItemListForAssociate(params) {
    return http.instance.get("/getItemListForAssociate?" + params)
  }
  SaveContactAsPrimary(params) {
    return http.instance.post("/SaveContactAsPrimary", { postString: params })
  }
  GetCallLogsListinge(params) {
    return http.instance.get("/Form/GetCallLogsListing?" + params)
  }
  additionalLeadOwnerListing(params) {
    return http.instance.get("/additionalLeadOwnerListing?" + params)
  }
  reopenLead(params) {
    return http.instance.post("/ReopenLead", { postString: params })
  }
  PrimaryContactDetails(params) {
    return http.instance.get("/PrimaryContactForDeal?" + params);
  }
  CheckDuplicate(params) {
    return http.instance.get("/Form/CheckDuplicate?" + params);
  }
  // Addtask(params) {
  //   return http.instance.post("/Addtask", { postString: params });
  // }
  DeleteAttachment(params) {
    return http.instance.get("/DeleteAttachment?" + params);
  }
  SaveIndustryType(params) {
    return http.instance.post("/Form/SaveIndustryType", { postString: params, type: 'Industry' })
  }
  ConvertLead(params) {
    return http.instance.post("/ConvertLead", { postString: params })
  }
  RevertToLead(params) {
    return http.instance.post("/RevertAction", { postString: params })
  }
  SaveContactRoleTitle(params) {

    return http.instance.post("/SaveContactRoleTitle", { postString: params })
  }
  SaveLeadSource(params) {
    return http.instance.post("/Form/SaveIndustryType", { postString: params })
  }
  PostDealOrContract(url, params) {
    return http.instance.post(url, { postString: params });
  }
  UploadFile(params) {
    return http.instance.post("/Form/UploadFile", { postString: params });
  }

  DownloadAttachment(params) {
    return http.instance.get("/Form/DownloadAttachment?" + params);
  }
  SaveScheduleCall(params) {
    return http.instance.post("/SaveScheduleCall", { postString: params });
  }
  SearchCriteriaListing(params) {
    return http.instance.get("/Form/SearchCriteriaListing?" + params);
  }
  ClearView(params) {
    return http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  GetViewList(params) {
    return http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  CommonCommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }
  GetViewById(params) {
    return http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  salesOrderListing(params) {
    return http.instance.get("/salesOrderListing?" + params)
  }
  getStageMappListing(params) {
    return http.instance.get("/getStageMappListing?" + params)
  }
  ApplyView(params) {
    return http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  SaveView(params) {
    return http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }
  ViewListFields(params) {
    return http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  ViewListUsers(params) {
    return http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  GetAllLeadOwnerPermission(params) {

    return http.instance.get("/GetAllLeadOwnerPermission?" + params)
  }
  saveAdditionalOwnerPermission(params) {

    return http.instance.post("/saveAdditionalOwnerPermission", { postString: params });
  }
  saveAdditionalDealOwnerPermission(params) {
    return http.instance.post("/saveAdditionalDealOwnerPermission", { postString: params });
  }
  ChangeOwner(params) {
    return http.instance.post("/ChangeOwner", { postString: params });
  }
  async getLeaOwnerBySearch(params) {
    return await http.instance.get("/Form/GetDDLData?" + params);
  }
  GetEmailData(params) {

    return http.instance.get("/Form/GetEmailData?" + params)
  }
  AddEmailTemplate(params) {

    return http.instance.post("/Form/AddEmailTemplate", { postString: params });
  }
  GetEmailLog(params) {

    return http.instance.get("/Form/GetEmailLog?" + params)
  }

  CloseLead(params) {

    return http.instance.post("/CloseLead", { postString: params });
  }
  GetDDLDataBySubModule(params) {

    return http.instance.get("/Form/GetDDLDataBySubModule?" + params)
  }
  SendEmail(params) {
    return http.instance.post("/Form/SendEmail", { postString: params });
  }
  SaveExistingContactWithMapping(params) {

    return http.instance.post("/SaveExistingContactWithMapping", { postString: params });
  }
  SaveContactWithMapping(params) {
    return http.instance.post("/SaveContactWithMapping", { postString: params });
  }
  SalesOrderListing(params) {
    return http.instance.get("/SalesOrderListing/", { params });
  }
  ConvertOrder(params) {
    return http.instance.post("/ConvertOrder/", { postString: params });
  }
  GetSingleContactDetails(params) {

    return http.instance.get("/GetSingleContactDetails?" + params)
  }
  CommonDeleteData(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { objectPostString: params })
  }
  SaveProductForMapping(params) {

    return http.instance.post("/SaveProductForMapping", { postString: params });
  }
  DeleteAccountBeforeDeal(params) {
    return http.instance.post("/DeleteAccountBeforeDeal", { postString: params });
  }
  PurchaseOrderListing(params) {
    return http.instance.get("/PurchaseOrderListing/", { params });
  }
  StageListing(params) {
    return http.instance.get("/StageListing/", { params });
  }
  VendorListing(params) {
    return http.instance.get("/VendorListing/", { params });
  }
  PriceBookListing(params) {
    return http.instance.get("/PriceBookListing/", { params });
  }
  QuotesListing(params) {
    return http.instance.get("/QuotesListing/", { params });
  }
  QuotesListingWthQueryString(params) {
    return http.instance.get("/QuotesListing?" + params);
  }
  GoodInwardListing(params) {
    return http.instance.get("/GoodInwardListing?" + params);
  }
  AccountList(params) {
    return http.instance.get("/AccountList?", { params });
  }
  changestausInwards(params) {
    return http.instance.post("/changestausInwards", { postString: params });
  }
  GetGoodInwardAssociatedProducts(params) {
    return http.instance.get("/GetGoodInwardAssociatedProducts?" + params);
  }
  GetMultiLocationByCompanyId(params) {
    return http.instance.get("/GetMultiLocationByCompanyId?" + params);
  }
  GetGoodInwardForPurchaseOrder(params) {
    return http.instance.get("/GetGoodInwardForPurchaseOrder?" + params);
  }
  QuotationDetails(params) {
    return http.instance.get("/QuotationDetails?" + params);
  }
  GetCommunicationModesList(params) {
    return http.instance.get("/CommunicationModesListing/", { params });
  }
  BrandListing(params) {
    return http.instance.get("/BrandListing?", { params });
  }
  FetchAddEditBrandData(params) {
    return http.instance.get("/BrandListing?brandid=" + params);
  }
  GetGoodInwardForVendor(params) {
    return http.instance.get("/GetGoodInwardForVendor?" + params);
  }
  CampaignListing(params) {
    return http.instance.get("/CampaignListing?", { params });
  }
  InvoiceListing(params) {
    return http.instance.get("/InvoiceListing/", { params });
  }
  ItemTypeListing(params) {
    return http.instance.get("ItemTypeListing?", { params });
  }
  CommunicationModesDelete(params) {
    return http.instance.post("/CommunicationModesDelete" + params);
  }
  CommunicationModesUpdateStatus(params) {
    return http.instance.post("/CommunicationModesUpdateStatus" + params);
  }
  SaveBrand(params) {
    return http.instance.post("/SaveBrand", { postString: params });
  }
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList");
  }
  GetFullMenu() {
    return http.instanceCommon.get("/Common/GetFullMenu");
  }
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?", { params });
  }
  GetFeedBack(params) {
    return http.instance.get("GetFeedBack?", { params });
  }
  GetCallLogsListing(params) {
    return http.instance.get("GetCallLogsListing?", { params });
  }

  ContactGroupListing(params) {
    return http.instance.get("/ContactGroupListing/", { params })
  }
  ScheduleListing(params) {
    return http.instance.get("/Form/ScheduleListing" + params);
  }
  CheckDuplicate(params) {
    return http.instance.get("/Form/CheckDuplicate?" + params)
  }
  SaveContactGroup(params) {
    return http.instance.post("/SaveContactGroup", { postString: params })
  }
  GetDDLDataForAddGroup(params) {
    return http.instance.get("/Form/GetDDLData?" + params);
  }
  SaveAccountAddress(params) {
    return http.instance.post("/SaveAccountAddress", { postString: params });
  }
  AccountAddressListing(params) {
    return http.instance.get("/AccountAddressListing?" + params);
  }
  ContractListing(params) {
    return http.instance.get("/ContractListing/", { params });
  }
  SaveTax(params) {
    return http.instance.post("/Form/SaveTax", { postString: params });
  }
  GetProductTypeTax(params) {
    return http.instance.get("GetProductTypeTax?", { params });
  }
  DeleteClient(params) {
    return http.instance.post("/DeleteClient", { postString: params })
  }
  SaveClientInfo(params) {
    return http.instance.post("/SaveClientInfo", { postString: params })
  }
  UserRoleList(params) {
    return http.instance.get("/UserRoleList?" + params);
  }
  GetProductList(params) {
    return http.instance.get("/ItemListing/", { params });
  }
  SavePriceBookForMapping(params) {
    return http.instance.get("/SavePriceBookForMapping/", { postString: params });
  }
  PostPriceBook(params) {
    return http.instance.post("/SavePriceBook", { postString: params })
  }
  PostContract(params) {
    return http.instance.post("/PostContract", { postString: params })
  }
  GetProductList(params) {
    return http.instance.get("/ItemListing/", { params });
  }
  PostDeal(params) {
    return http.instance.post("/PostDeal", { postString: params });
  }
  SaveVendor(params) {
    return http.instance.post("/SaveVendor", { postString: params });
  }
  SaveStage(params) {
    return http.instance.post("/SaveStage", { postString: params });
  }
  SaveContact(params) {
    return http.instance.post("/SaveContact", { postString: params });
  }
  GetStatusList(params) {
    return http.instance.get("/GetStatusList?" + params);
  }
  CommunicationModesAdd(params) {
    return http.instance.post("/ManageCommunicationMode", { postString: params });
  }
  GetCommunicationModeData(params) {
    return http.instance.get("/GetCommunicationModeById?id=" + params);
  }
  PostCampaign(params) {
    return http.instance.post("/SaveCampaign", { postString: params });
  }
  SaveGoodInward(params) {
    return http.instance.post("/SaveGoodInward", { postString: params });
  }
  getPurchaseOrderProductDetails(params) {
    return http.instance.get("/getPurchaseOrderProductDetails?" + params);
  }
  SaveOrder(params) {
    return http.instance.post("/SaveOrder", { postString: params });
  }
  CheckItemQuantity(params) {
    return http.instance.get("/CheckItemQuantity?" + params);
  }
  GetTaxByProduct(params) {
    return http.instance.get("/GetTaxByProduct?" + params);
  }
  GetAddressdata(params) {
    return http.instance.get("/GetAddressdata?" + params);
  }
  GetQuoteProductbyDeal(params) {
    return http.instance.get("/GetQuoteProductbyDeal?" + params);
  }
  GetAccountTypes() {
    return http.instance.get("/GetAccountTypes");
  }
  SaveAccountInfo(params) {
    return http.instance.post("/SaveAccountInfo", { postString: params });
  }
  UpdateStatusAccount(params) {
    return http.instance.put("/UpdateStatusAccount?" + params);
  }
  TemplateListing(params) {
    return http.instance.get("/Form/TemplateListing?" + params);
  }
  ContactGroupListing(params) {
    return http.instance.get("/ContactGroupListing?" + params);
  }
  SaveEmailScheduler(params) {
    return http.instance.post("/SaveEmailScheduler", { postString: params });
  }
  AddGroupEmailTemplate(params) {
    return http.instance.post("/AddGroupEmailTemplate", { postString: params });
  }
  PriceBookProduct(params) {
    return http.instance.get("/PriceBookProduct?" + params);
  }
  GetArticleList(params) {
    return http.instanceCommon.get("/HelpAndGuide/GetArticleList?" + params);
  }
  GetModulesSubModules() {
    return http.instanceCommon.get("/HelpAndGuide/GetSubscribedModulesList?");
  }
  GetArticleDetailById(params) {
    return http.instanceCommon.get("/HelpAndGuide/GetArticleDetailById?" + params);
  }
  GetUserTitlebycompanyId() {
    return http.instanceCommon.get("/HelpAndGuide/GetUserTitleByCompanyId?");
  }
  GetTimeZoneList() {
    return http.instanceCommon.get("/HelpAndGuide/GetTimeZone?");
  }
  DownloadSingleFile(params) {

    return http.instanceCommon.get("/HelpAndGuide/DownloadSingleFile?" + params, { responseType: "blob" });
  }
  DownloadZipFile(params) {
    
    return http.instanceCommon.get("/HelpAndGuide/DownloadZipFile?" + params, { responseType: "blob" });
  }
  GetCountryList() {
    return http.instanceCommon.get("/HelpAndGuide/GetCountryList?");
  }
  GetStateList(params) {

    return http.instanceCommon.get("/HelpAndGuide/GetStateListByCountryId?" + params);
  }
  GetTagList() {

    return http.instanceCommon.get("/HelpAndGuide/GetTagList?");
  }
  GetStutusList(params) {

    return http.instanceCommon.get("/HelpAndGuide/GetStatusList?" + params);
  }
  GetPopularSearches(params) {
    return http.instanceCommon.get("/HelpAndGuide/GetPopularSearchesList?" + params);
  }
  GetDynamicReport(params){
        return http.instanceCommon.get("/Dashboard/GetDynamicReport?" + params);
  }
  async SaveDashboard(params) {
    return await http.instanceCommon.post("/Dashboard/SaveDashboard", { postString: params });
  }
  async SaveReport(params) {
    return await http.instanceCommon.post("/Dashboard/SaveDashboardReport", { postString: params });
  }
  GetDashboard(params){
    return http.instanceCommon.get("/Dashboard/GetDashboard?" + params);
  }
  GetDashboardReport(params){
    return http.instanceCommon.get("/Dashboard/GetDashboardReport?" + params);
}
async GetDashboardData(params){
  return await http.instanceCommon.get("/Common/GetDashboardData?"+params);
}
GetStateListByCountryCode(params){
  return http.instanceCommon.get("/Dashboard/GetStateListByCountryCode?" + params);
}
GetStartEndDate_New(params){
  return http.instanceCommon.get("/Dashboard/GetStartEndDate_New?"+params);
}
GetParentClientByCompanyIdForGroup_New(){
  return http.instanceCommon.get("/Dashboard/GetParentClientByCompanyIdForGroup_New");
}
GetChartType(){
  return http.instanceCommon.get("/Dashboard/GetChartType");
}
GetChartFileds(params){
  return http.instanceCommon.get("/Dashboard/GetChartFileds?"+params);
}
async EmailScheduleListing(params) {
  return await http.instanceCommon.get("/ManageDynamicReport/GetScheduleEmailListing", { params });
}
async GetScheduleReportHistoryListing(params) {
  return await http.instanceCommon.get("/ManageDynamicReport/GetScheduleReportHistoryListing", { params });
}
async CheckPrivilege(params){
  return await http.instanceCommon.get("/Common/HasPermission?" + params);
}
async SaveEditsInDashboardReports(params) {
  return await http.instanceCommon.post("/Common/SaveDashboardEditedData", { postString: params });
}
async SaveDashboardDefaultReport(params) {
  return await http.instanceCommon.post("/Dashboard/SaveDashboardDefaultData?" + params );
}
async RemoveDashboardDefaultData(params) {
  return await http.instanceCommon.post("/Dashboard/RemoveDashboardDefaultData?" + params );
}
async GetDashboardShareData(params) {
  return await http.instanceCommon.get("/Dashboard/GetDashboardShareData?" + params );
}
async SaveDashboardShareReport(params) {
  // return await http.instanceCommon.post("/Dashboard/SaveDashboardShareData" , { postString: params });
  return await http.instanceCommon.post("/Dashboard/SaveDashboardShareData?" + params );
}
async DeleteAssignedShareDashboard(params) {
  return await http.instanceCommon.post("/Dashboard/DeleteAssignedShareDashboard?" + params );
}
async deleteActivityData(params) {
  return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
}
async GetAllModule(){
  return await http.instanceCommon.get("/Configuration/GetAllModule");
}
async GetKeys(params){
  return await http.instanceCommon.get("/Configuration/GetKeys?" + params);
}
// async GetModuleKeys(params){
//   return await http.instanceCommon.get("/Common/GetModuleKeys?" + params);
// }
async ModuleValidationMessages(params){
  return await http.instanceCommon.get("/Configuration/ModuleValidationMessages?" + params);
}
async UpdateCompanySetupConfig(params){
  return await http.instanceCommonFile.post("/Configuration/Update" , params);
}
async GetConfigNumberJsonData(params){
  return await http.instance.get("/Form/GetConfigNumberJsonData?" + params);
}
async GetBlobUrl(){
  return await http.instanceCommon.get("/Common/GetBlobUrl");
}
GetTimeZone() {
  return http.instanceCommon.get("/Common/GetTimeZone?");
}

TemplateListByCategory(param) {
  return http.instanceCommon.get("/Configuration/TemplateListByCategory?" + param);
}
async SaveConfiguringJsonData(params) {          
  return await http.instance.post("/Form/SaveConfiguringJsonData", { postString: params});
}
TemplateListForSalesOrder() {
  return http.instanceCommon.get("/Configuration/TemplateListForSalesOrder?");
}
GetAllShift() {
  return http.instanceCommon.get("/Wizard/GetAllShift?" );
}
GetAllDesignation() {
  return http.instanceCommon.get("/Wizard/GetAllDesignation?" );
}
GetAllDepartment() {
  return http.instanceCommon.get("/Configuration/GetAllDepartment?");
}
GetCompanyLocation() {
  return http.instanceCommon.get("/Configuration/GetCompanyLocation?");
}
GetCurrency() {
  return http.instanceCommon.get("/Common/GetCurrency?");
}
DeleteShareUserRole(params) {
  return http.instanceCommon.post("/Share/DeleteAssignedShareUserRole?" + params);
}
GetContractList(params) {
  
  return http.instanceCommon.get("/Dashboard/GetContractList?" + params);
}
GetFilterStateList(params) {
  
  return http.instanceCommon.get("/Dashboard/GetStateList?" + params);
}
GetBillerList(params) {
  
  return http.instanceCommon.get("/Dashboard/GetBillerList?" + params);
}
GetQuickAddDataListing() {
  return  http.instanceCommon.get("/Common/GetQuickAddLayoutList");
}
GetFormCustomLayout(params) {    
  return  http.instanceCommon.get("/FormLayout/GetForm?" + params);
}
async SaveDynamicForm(params) {
  return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
}
async GetLocationByuserId(params) { 
      return await http.instanceAsset.get("/AssetAssignment/GetLocationByUserId?" + params);
}
async GetUserImage(params){
  return await http.instanceCommon.get("/Common/GetUserImage?" + params);
}
async GetLocationByCompanyId(param) {
  return await http.instanceAsset.get("/GetLocationByCompanyId?" + param);
}
IsDuplicateAssetCatalog(params) {
  return http.instanceAsset.post("/IsDuplicateAssetCatalog?", { postString: params });
}
GetAllowFileType(){
  return http.instanceAsset.get("/CheckAllowFileType");
}
async GetDDLData(params) {
  return await http.instanceCommon.get("/Common/GetDDLData?" + params);
}
async GetToDoListing(params){
  return await http.instanceEvent.get("/Project/ToDoList?" + params);
}
StatusDropdown(params) {
  return http.instanceEvent.get("/Project/StatusListing?"+ params);
}
async TimeTrackWeeklyDataList(params){
  return await http.instanceEvent.get("/Project/TimerWeeklyHoursListing?" + params);
}
async FileListing(params){
  return await http.instanceEvent.get("/Project/FileListing?" + params);
}
async GetQuickViewByProjectIdTaskId(params){
  return await http.instanceEvent.get("/Project/GetQuickViewByProjectIdTaskId?" + params);
}
UpdateStatus(params) {
  return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
}
async Post(params){
  return await http.instanceEvent.post("/Project/Post" , params);
}
async RequestTime(params){
  return await http.instanceEvent.post("/Project/RequestTime" ,{ postString: params });
}
async DownloadFile(params){
  return http.docstrage.get("/DownloadFileSingle?" + params,{responseType:"blob"});
}
async NoteListingByProjectaAndTask(params){
  return await http.instanceEvent.get("/Project/NoteListingByProjectaAndTask?" + params);
}
async saveNote(params) {
  return await http.instanceEvent.post("/Project/SaveNote", { postString: params })
}
async LoadTaskByProjectId(params)
{
  return await http.instanceEvent.get("/Project/LoadTaskByProjectId?" + params);
}
async UpdateProgress(params)
{
  return await http.instanceEvent.post("/Project/SaveTaskLog" ,{ postString: params });
}
async TaskLogCommentDetail(params){
  return await http.instanceEvent.get("/Project/TaskLogCommentListing?"+params);
}
async GetShowLocationsAndMap(params)
{
  return await http.instanceEvent.get("/Project/GetShowLocationsAndMap?" + params);
}
async GetChecboxUserData(params){
  return await http.instanceEvent.get("/Project/UserList?"+params);
}
async Create(params){
  return await http.instanceEvent.post("/Project/Create", params);  
}
async SaveShortcutData(params) {
  
  return await http.instanceCommon.post("/Dashboard/SaveShortcutData", { postString: params });
}
}
export default new DataService();
